<template>
  <div class="progress">
    <div class="top_box">
      <div class="title_box">
        <div class="title">审核进度</div>
        <div class="proj_name">{{ proj_name }}</div>
      </div>
      <van-tabs v-model="activeName" type="line" @change="changeTab" v-if="basicInformation.subprojects[0].is_followup == 1" :line-width="isLine ? '50%' : '0'" ref="tabs">
        <van-tab title="阶段病例" name="阶段病例"></van-tab>
        <van-tab title="随访信息" name="随访信息"></van-tab>
      </van-tabs>
      <!-- 搜索框 -->
      <van-search v-model="nameValue" shape="round" placeholder="搜索患者姓名" class="search_box" @search="onSearch" @clear="onClear" @input="onInput" show-action>
        <template #action>
          <div class="icon_box">
            <!-- <div @click="onSort()" class="sort_icon">
                        <van-icon v-if="up_or_down == 'down'" name="descending" size="30"/>
                        <van-icon v-if="up_or_down == 'up'" name="ascending" size="30"/>
                    </div> -->
            <div @click="onFilter()" class="sort_icon"><van-icon name="descending" size="25" /></div>
          </div>
        </template>
      </van-search>
    </div>
    <!-- 排序弹窗 -->
    <van-popup v-model="ifShowSort" position="top" style="height: auto">
      <div class="sort_title">
        <span>排序方式</span>
        <span class="default_text" @click="onSortDefaut()">恢复默认</span>
      </div>
      <van-grid :column-num="1" clickable>
        <van-grid-item @click="onTimeSort('down')">
          <template #text>
            <div class="sort_type_text" :class="sort_type == 'date' && up_or_down == 'down' ? 'on_type' : ''">上传时间近到远（默认）</div>
          </template>
        </van-grid-item>
        <van-grid-item @click="onTimeSort('up')">
          <template #text>
            <div class="sort_type_text" :class="sort_type == 'date' && up_or_down == 'up' ? 'on_type' : ''">上传时间远到近</div>
          </template>
        </van-grid-item>
        <van-grid-item @click="onNameSort('up')">
          <template #text>
            <div class="sort_type_text" :class="sort_type == 'name' && up_or_down == 'up' ? 'on_type' : ''">姓名首字母a到z</div>
          </template>
        </van-grid-item>
        <van-grid-item @click="onNameSort('down')">
          <template #text>
            <div class="sort_type_text" :class="sort_type == 'name' && up_or_down == 'down' ? 'on_type' : ''">姓名首字母z到a</div>
          </template>
        </van-grid-item>
      </van-grid>
    </van-popup>
    <van-tabs class="tabs_box" type="line" border title-active-color="#4B99FF" color="#474747" swipeable v-if="activeName == '阶段病例'" :line-width="isLine ? '20%' : '0'" ref="tab">
      <van-tab title="等待上传" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne" v-if="item.audit_status == '待上传'" @click="openSelect(item, index)">
              <div class="content">
                <div class="head">
                  <!-- <img src="/static/file.png" /> -->
                  <div class="case_item_box">
                    <div class="case_name">
                      {{ item.subscheme ? (short_name == 'bxzleq' && item.subscheme.name.indexOf('肠癌') != -1 ? '结直肠癌' : item.subscheme.name) : item.scheme.name }}
                    </div>
                    <span>
                      {{ stage_name(item) }}
                      <!-- {{' 病例ID:'+item.id+' / 第'+item.phase+'阶段' + ((short_name == 'fcah' && item.subscheme.name.indexOf('2.0') > 0) ? ((item.phase%2 == 0) ? '（后续）' : '（首次）') : '')}} -->
                    </span>
                  </div>
                </div>
                <div class="bottom">
                  <div>
                    <div class="patient patient_name">
                      {{ item.patient.mobile ? initName(item.patient.name) + '/' + item.patient.mobile : initName(item.patient.name) }}
                    </div>
                    <div class="time">{{ record_show_date == 'updated_at' ? '病例资料更新时间' : '病例提交时间' }}：{{ record_show_date == 'updated_at' ? item.updated_at : item.first_upload_date }}</div>
                  </div>
                  <div class="icon">
                    <img :src="statusImg(item)" />
                    <div class="status" :style="{ color: getcolor(item) }">{{ item.audit_status }}</div>
                  </div>
                </div>

                <!-- <div class="status" :style="{color:getcolor(item)}">
                                    {{item.audit_status}}
                                </div> -->
              </div>
              <!-- <div class="icon">
                                <img :src="statusImg(item)" />
                                <div class="status" :style="{color:getcolor(item)}">{{item.audit_status}}
                                </div>
                            </div> -->
              <!-- <van-icon class="delete" name="clear" @click.stop="delRecord(item, index)" /> -->
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无病例信息</div>
          </div>
          <div class="btn_box" v-if="checkNew()">
            <van-button round type="info" :disabled="isDisabled" class="create_case_btn" @click="next">创建病例信息</van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="正在审核" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne" v-if="item.audit_status == '等待审核'" @click="openSelect(item, index)">
              <div class="content">
                <!-- <div class="head">
                                    <img src="/static/file.png" />
                                    <span>{{item.subscheme?( (short_name == 'bxzleq' && item.subscheme.name == '方案二') ? '结直肠癌' : item.subscheme.name):item.scheme.name}}{{' / 病例ID:'+item.id+' / 第'+item.phase+'阶段'}}</span>
                                </div> -->
                <div class="head">
                  <!-- <img src="/static/file.png" /> -->
                  <div class="case_item_box">
                    <div class="case_name">
                      {{ item.subscheme ? (short_name == 'bxzleq' && item.subscheme.name.indexOf('肠癌') != -1 ? '结直肠癌' : item.subscheme.name) : item.scheme.name }}
                    </div>
                    <span>
                      <!-- {{' 病例ID:'+item.id+' / 第'+item.phase+'阶段'}} -->
                      {{ stage_name(item) }}
                    </span>
                  </div>
                </div>
                <div class="bottom">
                  <div>
                    <div class="patient">
                      {{ item.patient.mobile ? initName(item.patient.name) + '/' + item.patient.mobile : initName(item.patient.name) }}
                    </div>
                    <div class="time">{{ record_show_date == 'updated_at' ? '病例资料更新时间' : '病例提交时间' }}：{{ record_show_date == 'updated_at' ? item.updated_at : item.first_upload_date }}</div>
                  </div>
                  <div class="icon">
                    <img :src="statusImg(item)" />
                    <div class="status" :style="{ color: getcolor(item) }">{{ item.audit_status }}</div>
                  </div>
                </div>

                <!-- <div class="status" :style="{color:getcolor(item)}">
                                    {{item.audit_status}}
                                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无病例信息</div>
          </div>
          <div class="btn_box" v-if="checkNew()">
            <van-button round type="info" :disabled="isDisabled" class="create_case_btn" @click="next">创建病例信息</van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="审核失败" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne" v-if="item.audit_status == '审核驳回'" @click="openSelect(item, index)">
              <div class="content">
                <div class="head">
                  <div class="case_item_box">
                    <div class="case_name">
                      {{ item.subscheme ? (short_name == 'bxzleq' && item.subscheme.name.indexOf('肠癌') != -1 ? '结直肠癌' : item.subscheme.name) : item.scheme.name }}
                    </div>
                    <span>
                      {{ stage_name(item) }}
                    </span>
                  </div>
                </div>
                <div class="bottom">
                  <div>
                    <div class="patient">
                      {{ item.patient.mobile ? initName(item.patient.name) + '/' + item.patient.mobile : initName(item.patient.name) }}
                    </div>
                    <div class="time">{{ record_show_date == 'updated_at' ? '病例资料更新时间' : '病例提交时间' }}：{{ record_show_date == 'updated_at' ? item.updated_at : item.first_upload_date }}</div>
                  </div>
                  <div class="icon">
                    <img :src="statusImg(item)" />
                    <div class="status" :style="{ color: getcolor(item) }">{{ item.audit_status }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无病例信息</div>
          </div>
          <div class="btn_box" v-if="checkNew()">
            <van-button round type="info" :disabled="isDisabled" class="create_case_btn" @click="next">创建病例信息</van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="审核成功" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne wrap" v-if="item.audit_status == '审核通过'" @click="openSelect(item, index)">
              <div class="content_box">
                <div class="content">
                  <div class="head">
                    <div class="case_item_box">
                      <div class="case_name">
                        {{ item.subscheme ? (short_name == 'bxzleq' && item.subscheme.name.indexOf('肠癌') != -1 ? '结直肠癌' : item.subscheme.name) : item.scheme.name }}
                      </div>
                      <span>
                        {{ stage_name(item) }}
                      </span>
                    </div>
                  </div>
                  <div class="bottom">
                    <div>
                      <div class="patient" v-if="item.patient">
                        {{ item.patient.mobile ? initName(item.patient.name) + '/' + item.patient.mobile : initName(item.patient.name) }}
                      </div>
                      <div class="time">{{ record_show_date == 'updated_at' ? '病例资料更新时间' : '病例提交时间' }}：{{ record_show_date == 'updated_at' ? item.updated_at : item.first_upload_date }}</div>
                    </div>
                    <div class="icon">
                      <img :src="statusImg(item)" />
                      <div class="status" :style="{ color: getcolor(item) }">
                        <!-- {{item.audit_status}} -->
                        {{ item.pay_status == '等待发放' ? '审核通过' : item.pay_status == '发放成功' ? '提取成功' : item.pay_status == '发放失败' ? '提取失败' : item.pay_status }}
                        <div v-if="item.pay_status == '等待领取'" class="status-info">请等待1-2个工作日</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="shape_bg" :class="item.editNextTip == 1 ? 'taller-shape-bg' : ''" v-if="item.canNextRecord == 1 && checkOpe(item, 10) && (short_name == 'llsh' ? (item.is_special == 1 || item.phase != 1 ? true : false) : true)">
                <template v-if="short_name === 'iga'">30天后请上传下一阶段随访信息</template>
                <template v-else>
                  {{ item.editNextTip == 1 ? '该患者本阶段用药时间已经达到42天，请尽快上传下一阶段病例' : '请尽快上传下一阶段' }}
                </template>
              </div> -->
              <div class="shape_bg" :class="item.editNextTip == 1 ? 'taller-shape-bg' : ''" v-if="item.canNextRecord == 1 && checkOpe(item, 10)">
                <template v-if="short_name === 'iga'">30天后请上传下一阶段随访信息</template>
                <template v-else>
                  {{ item.editNextTip == 1 ? '该患者本阶段用药时间已经达到42天，请尽快上传下一阶段病例' : '请尽快上传下一阶段' }}
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无病例信息</div>
          </div>
          <div class="btn_box" v-if="checkNew()">
            <van-button round type="info" :disabled="isDisabled" class="create_case_btn" @click="next">创建病例信息</van-button>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-tabs class="tabs_box" type="line" border title-active-color="#4B99FF" color="#474747" swipeable v-if="activeName == '随访信息'" :line-width="isLine ? '20%' : '0'" ref="tab">
      <van-tab title="待上传" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne" v-if="item.status == '待上传'" @click="openSelect(item, index)">
              <div class="content">
                <div class="head">
                  <div class="case_item_box">
                    <div class="case_name">
                      {{ item.subproject ? item.subproject.name : '' }}
                    </div>
                    <span>
                      {{ `病历ID:${item.record_id}/${item.count}` }}
                    </span>
                  </div>
                </div>
                <div class="bottom">
                  <div>
                    <div class="patient patient_name">
                      {{ item.patient ? initName(item.patient.name) : '' }}
                    </div>
                    <div class="time">上传时间：{{ item.created_at }}</div>
                  </div>
                  <div class="icon">
                    <img :src="statusImg(item)" />
                    <div class="status" :style="{ color: getcolor(item) }">{{ item.status }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无随访信息</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="上传完成" class="tabs">
        <div class="messageList" v-if="caseList.length > 0">
          <div v-for="(item, index) in caseList" :key="index">
            <div class="messageOne wrap" v-if="item.status == '已提交'" @click="openSelect(item, index)">
              <div class="content_box">
                <div class="content">
                  <div class="head">
                    <div class="case_item_box">
                      <div class="case_name">
                        {{ item.subproject ? item.subproject.name : '' }}
                      </div>
                      <span>
                        {{ `病历ID:${item.record_id}/${item.count}` }}
                      </span>
                    </div>
                  </div>
                  <div class="bottom">
                    <div>
                      <div class="patient">
                        {{ item.patient ? initName(item.patient.name) : '' }}
                      </div>
                      <div class="time">上传时间：{{ item.created_at }}</div>
                    </div>
                    <div class="icon">
                      <img :src="statusImg(item)" />
                      <div class="status" :style="{ color: getcolor(item) }">上传完成</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_box">
          <div class="img_box">
            <img src="/static/none_case.svg" alt="" />
            <div class="tip_text">暂无随访信息</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>

    <div class="btn" v-if="caseList.length > 0 && activeName !== '随访信息'">
      <van-button v-if="checkNew()" round type="info" :disabled="isDisabled" size="large" class="up" @click="next">创建病例信息</van-button>
    </div>
    <div class="dialog_layer" v-if="showDialog">
      <Dialog1 :status="dialogStatus" v-if="showDialog" :dialogRemark="dialogRemark" @confirm="onConfirm" @close="onClose"></Dialog1>
    </div>
    <van-dialog v-model="showBank" title="银行卡信息" :show-confirm-button="false">
      <van-form class="from" style="margin: 16px 10px" input-align="right">
        <van-field v-model="bankForm.bancard_num" label="银行卡号" required placeholder="请输入银行卡号" type="digit" />
        <van-field label="开户行" required readonly name="picker" placeholder="请选择开户行" :value="bankForm.bank_name" rows="1" autosize maxlength="500" type="textarea" right-icon="arrow-down" @click="showBankList = !showBankList" />
        <van-field v-model="bankForm.bank_branch" label="开户支行" required placeholder="开户支行必须填写XX省XX市XX支行" rows="1" autosize maxlength="500" type="textarea" />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" :disabled="bankDisabled" @click="bankSubmit">确定</van-button>
        </div>
      </van-form>
    </van-dialog>
    <van-popup v-model="showBankList" round position="bottom" :style="{ height: '400px' }" @click-overlay="showBankList = false">
      <!-- <form action="/">
            <van-search v-model="bankKeyword" placeholder="请输入搜索关键词" @clear="bankKeyword = ''" />
            <van-picker :default-index="findIndex(bankList, bankForm.bank_name)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" :style="{height:'246px'}" @cancel="showBankList = false" @confirm="confirmBank" />
            </form> -->
      <van-picker :default-index="findIndex(bankList, bankForm.bank_name)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" @cancel="showBankList = false" @confirm="confirmBank">
        <template #title>
          <van-search class="bank_search" v-model="bankKeyword" input-align="left" shape="round" placeholder="搜索开户行名称" @clear="bankKeyword = ''" />
        </template>
      </van-picker>
    </van-popup>
    <!-- <mobileVerify v-if="showVerify&&!this.doctorInfo.has_empower" :afterModifyMobile="afterModifyMobile" :doctorId="verifyDoctorId" :projectId="doctorInfo.login_project_id" :bankcard="verifyBankcard" :title="'非本人使用手机号码'" :tips="'请重新输入本人手机号及银行卡号'"></mobileVerify> -->
    <van-action-sheet v-model="showAction" :actions="actions" @select="onSelect" close-on-click-action />

    <close-notice></close-notice>
    <!-- 项目关闭公告 -->

    <froze-notice v-if="get_froze_status"></froze-notice>
    <!-- 医生冻结弹窗 -->
  </div>
</template>

<script>
import {
  doctor,
  record,
  getLinkScheme,
  recordCanSubmit,
  deleteRecord,
  deleteFollow,
  addBankcard,
  addBankList,
  cancelRecord,
  recordSort,
  getScheme,
  getSubschemeId, //鳞癌(新征程)项目获取新方案id
  followInfo,
  projectDoctor,
  limitRecord
} from '@/api/api'
import { bankCardLength } from '@/utils/utils'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Dialog1 from '@/components/dialog.vue'
import mobileVerify from '@/components/mobileVerify.vue'
import closeNotice from '@/components/closeNotice.vue'
import frozeNotice from '@/components/frozeNotice.vue'
import { Toast, Dialog } from 'vant'
export default {
  name: '',
  components: {
    Dialog1,
    mobileVerify,
    closeNotice,
    frozeNotice
  },
  data() {
    return {
      doctorExamineInfo: {},
      up_or_down: 'down', //默认降序
      sort_type: 'date', // 默认按照时间排序
      ifShowSort: false, // 是否显示排序弹窗
      nameValue: '', //要搜索的患者姓名
      showDialog: false,
      dialogRemark: '',
      dialogStatus: '',
      item: null,
      caseList: [], //展示的病例列表
      linkScheme: {
        scheme_id: '',
        subscheme_id: '',
        can_bankcard: null
      },

      // 银行卡信息
      showBank: false,
      bankList: [],
      showBankList: false,
      bankKeyword: '', // 开户银行关键字
      bankForm: {
        bancard_num: '', // 银行卡号
        bank_name: '', // 开户行
        bank_branch: '' // 开户支行
      },
      bankDisabled: false,
      // showVerify: false,
      // verifyDoctorId: null,
      // verifyBankcard: null,
      showAction: false,
      actions: [],
      currentIndex: 0,
      currentItem: null,
      cancelDisabled: false,
      isDisabled: false, //点击下一步时防并发
      proj_name: '', //项目名称
      ifShowCreateBtn: false, //是否显示创建病例信息按钮
      short_name: '', //项目缩写
      subscheme_id: null, //子方案id
      currentPatientType: '身份证',
      activeName: '阶段病例',
      is_followup: null, //是否开启随访
      template_id: null, //随访模版id
      isLine: true,
      project_email: '',
      is_sensitive: false
    }
  },
  computed: {
    ...mapState(['doctor_id', 'doctorInfo', 'userId', 'record_show_date', 'ifClosed', 'basicInformation', 'tabName']),
    ...mapGetters(['sub_id', 'get_froze_status', 'operate_limit'])
  },
  created() {
    console.log(this.doctorInfo)
  },
  async mounted() {
    this.proj_name = this.basicInformation.projects[0].name
    this.short_name = this.basicInformation.projects[0].short_name
    // 随访参数
    // this.is_followup=this.doctorInfo.subprojects[0].is_followup
    // this.template_id=this.doctorInfo.subprojects[0].template_id
    this.is_followup = this.basicInformation.subprojects[0].is_followup
    this.template_id = this.basicInformation.subprojects[0].template_id
    this.is_sensitive = this.basicInformation.subprojects[0].is_sensitive === '1'
    this.project_email = this.basicInformation.subprojects.find(item => item.id == this.sub_id)?.project_email
    // console.log(this.tabName, 'tabName', this.operate_limit)
    this.activeName = this.tabName ? this.tabName : '阶段病例'
    await limitRecord({
      params: { subproject_id: this.sub_id }
    }).then(res => {
      if (res) this.$store.commit('setOperateLimit', res)
    })
    await this.init()
    // console.log('this.record_show_date', this.record_show_date)
    // 项目相关信息
    // console.log('项目信息：', this.basicInformation)
    // console.log('doctorInfo', this.doctorInfo)
    // console.log(this.template_id, 'template_id')
  },
  methods: {
    ...mapMutations(['setClosePop']),
    ...mapActions(['GET_IF_CLOSED', 'GET_IF_FROZEN']),
    initName(name) {
      if (this.is_sensitive) {
        if (name.length <= 2) {
          return name.replace(/^(.).+$/, '$1*')
        } else {
          return name.replace(/^(.).+(.)$/, `$1${'*'.repeat(name.length - 2)}$2`)
        }
      } else {
        return name
      }
    },
    // (肥肠安好项目)阶段名显示
    stage_name(item) {
      if (this.short_name == 'fcah') {
        let phase = item.phase
        if (item.phase % 2 == 0) {
          phase = phase / 2
        } else {
          phase = phase - 1 == 0 ? 1 : phase - (phase - 1) / 2
        }
        const stage_phase = item.subscheme.name.indexOf('2.0') > 0 ? phase : item.phase
        const stage_type = item.subscheme.name.indexOf('2.0') > 0 ? (item.phase % 2 == 0 ? '（后续）' : '（首次）') : ''
        return '病例ID:' + item.id + ' / 第' + stage_phase + '阶段' + stage_type
      } else {
        return '病例ID:' + item.id + ' / 第' + item.phase + '阶段'
      }
    },
    follow_name(item) {
      return '病例ID:' + item.id
    },
    // 当输入框内容为空时，恢复列表
    onInput(value) {
      // console.log(value)
      if (value == '') {
        // console.log('空')
        this.useRecordSort(this.up_or_down)
      }
    },
    // 筛选按钮（选择按时间/姓名排序）
    onFilter() {
      this.ifShowSort = true
    },
    // 恢复默认排序
    onSortDefaut() {
      this.up_or_down = 'down'
      this.sort_type = 'date'
      this.useRecordSort(this.up_or_down)
      this.ifShowSort = false
    },
    // 选择按时间排序
    onTimeSort(str) {
      this.up_or_down = str
      console.log('选择按时间排序')
      this.sort_type = 'date'
      this.useRecordSort(str)
      this.ifShowSort = false
    },
    // 选择按姓名排序
    onNameSort(str) {
      this.up_or_down = str
      console.log('选择按姓名排序')
      this.sort_type = 'name'
      this.useRecordSort(str)
      this.ifShowSort = false
    },
    // 排序功能
    async onSort() {
      if (this.up_or_down == 'down') {
        this.up_or_down = 'up'
        console.log('改成', this.sort_type, '升序')
        // 获取升序列表
        this.useRecordSort(this.up_or_down)
      } else {
        this.up_or_down = 'down'
        console.log('改成', this.sort_type, '降序')
        // 获取降序列表
        this.useRecordSort(this.up_or_down)
      }
    },
    // 调用排序接口
    async useRecordSort(up_or_down) {
      // 获取升序列表 / 降序列表(参数有-为降序)
      await recordSort({
        params: {
          doctor_id: this.userId,
          subproject_id: this.sub_id,
          expand: 'doctor,patient,scheme,subscheme,nextRecord,canNextRecord,canCancel,subproject, editNextTip,canFollow,existFollow',
          sorted: this.sort_type == 'date' ? (up_or_down == 'up' ? 'date' : '-date') : up_or_down == 'up' ? 'patient_name' : '-patient_name',
          patient_name: this.nameValue,
          subscheme_id: this.short_name == 'linai' ? this.subscheme_id : null
        }
      })
        .then(res => {
          // console.log('排序res：', res)
          this.caseList = res.items
        })
        .catch(err => {
          console.log('排序err:', err)
        })
    },
    // 点击取消按钮，恢复初始列表
    onClear() {
      console.log('恢复列表')
      this.nameValue = ''
    },
    // 搜索患者姓名
    async onSearch() {
      this.useRecordSort(this.up_or_down)
    },
    onClose() {
      this.isLine = true
      if (this.basicInformation.subprojects[0].is_followup == 1) {
        this.$refs.tabs.resize()
      }
      this.$refs.tab.resize()
      this.showDialog = false
    },
    onConfirm() {
      this.isLine = true
      let item = this.item
      this.$router.replace({
        path: '/case',
        query: {
          record_id: item.id,
          scheme_id: item.scheme_id,
          subscheme_id: item.subscheme_id,
          phase: item.phase,
          patient_id: item.phase > 1 ? item.patient_id : '',
          remark: item.audit_status == '审核驳回' ? item.audit_remark : '',
          patient_type: this.currentPatientType
        }
      })
      this.item = null
    },
    progress(item) {
      // console.log(item, 'progressitem')
      // console.log(this.isShowLine);
      if (item.audit_status == '待上传') {
        if (this.short_name == 'jzt') {
          if (this.checkCreateDate(new Date(item.created_at.replace(/-/g, '/')))) {
            this.$router.replace({
              path: '/case',
              query: {
                record_id: item.id,
                scheme_id: item.scheme_id,
                subscheme_id: item.subscheme_id,
                phase: item.phase,
                patient_id: item.phase > 1 ? item.patient_id : '',
                if_next: false, //@是否为开启下一阶段
                patient_type: this.currentPatientType
              }
            })
          }
        } else {
          const created_at = new Date(item.created_at)
          const d = new Date(2022, 4, 12, 18, 50, 0, 0)
          let is_old_case = null
          if (this.short_name == 'fcah') {
            if (created_at > d) {
              is_old_case = false
            } else {
              is_old_case = true
            }
          }
          this.$router.replace({
            path: '/case',
            query: {
              record_id: item.id,
              scheme_id: item.scheme_id,
              subscheme_id: item.subscheme_id,
              phase: item.phase,
              patient_id: item.phase > 1 ? item.patient_id : '',
              if_next: false, //@是否为开启下一阶段
              is_old_case: this.short_name == 'fcah' ? is_old_case : '',
              patient_type: this.currentPatientType
            }
          })
        }
      } else if (item.audit_status == '审核驳回') {
        // this.$refs.tabs.border=false
        // console.log(this.$refs.tabs,'tab');
        this.isLine = false
        if (this.basicInformation.subprojects[0].is_followup == 1) {
          this.$refs.tabs.resize()
        }
        this.$refs.tab.resize()
        if (this.short_name == 'jzt') {
          if (this.checkCreateDate(new Date(item.created_at.replace(/-/g, '/')))) {
            this.item = item
            this.dialogStatus = 'error'
            this.dialogRemark = item.audit_remark
            this.showDialog = true
          }
        } else {
          this.item = item
          this.dialogStatus = 'error'
          this.dialogRemark = item.audit_remark
          this.showDialog = true
        }
      } else if (item.audit_status == '等待审核') {
        window.location.reload()
      } else if (item.audit_status == '审核通过') {
        if (item.canNextRecord == 1) {
          // console.log(item, 'canNextRecord')
          this.$router.replace({
            path: '/case',
            query: {
              scheme_id: item.scheme_id,
              subscheme_id: item.subscheme_id,
              phase: item.phase + 1,
              patient_id: item.patient_id,
              if_next: true, //@是否为开启下一阶段
              patient_type: this.currentPatientType
              // record_id: item.id,
            }
          })
        } else {
          Toast('该病例已上传完成')
        }
      } else if (item.status == '待上传') {
        // console.log(item, 'ITEM')
        this.$router.replace({
          path: '/case',
          query: {
            template_id: item.subproject.template_id,
            is_followup: item.subproject.is_followup,
            follow_id: item.id,
            patient_name: item.patient.name,
            patient_idsn: item.patient.idsn
          }
        })
      }
    },
    checkCreateDate(case_create_date) {
      //金字塔项目病例为2022年1月1日前创建的病例时等待上传和审核失败的病例不能进行编辑
      let deadLine = new Date(2022, 0, 1, 0, 0, 0, 0)
      if (case_create_date < deadLine) {
        Dialog.alert({
          title: '温馨提示',
          message: '很抱歉这份病例属于一期病例，您无法再次编辑',
          confirmButtonColor: '#4B99FF'
        })
        console.log('很抱歉这份病例属于一期病例，您无法再次编辑')
        return false
      } else {
        return true
      }
    },
    // 删除病例信息
    delRecord(item, index) {
      // console.log(item, 'delRecord')
      if (this.isDel) {
        return
      }
      const self = this
      if (item.count) {
        Dialog.alert({
          title: '是否删除以下病例?',
          message: item.subproject.name + '/' + item.count + '\n' + item.patient.name,
          theme: 'round-button',
          confirmButtonColor: '#4B99FF',
          closeOnClickOverlay: true
        })
          .then(() => {
            self.isDel = true
            deleteFollow({
              follow_id: item.id
            })
              .then(res => {
                self.isDel = false
                self.caseList.splice(index, 1)
                Toast('删除成功！')
              })
              .catch(err => {
                self.isDel = false
                Toast(err.response.data.message)
              })
          })
          .catch(() => {})
      } else {
        let msg
        if (item.existFollow && item.phase == 1) {
          msg = ''
        } else {
          msg = (item.subscheme ? item.subscheme.name : item.scheme.name) + '\n' + item.patient.name + '/' + (item.patient.mobile ? item.patient.mobile : '')
        }
        Dialog.alert({
          title: `${item.existFollow && item.phase == 1 ? '该病例下有随访信息，若删除该病例则随访信息一并删除，请确认是否删除？' : '是否删除以下病例？'}`,
          message: msg,
          theme: 'round-button',
          confirmButtonColor: '#4B99FF',
          closeOnClickOverlay: true,
          className: 'dialog'
        })
          .then(() => {
            self.isDel = true
            deleteRecord({
              record_id: item.id
            })
              .then(res => {
                self.isDel = false
                self.caseList.splice(index, 1)
                Toast('删除成功！')
              })
              .catch(err => {
                self.isDel = false
                Toast(err.response.data.message)
              })
          })
          .catch(() => {})
      }
    },
    cancelRecord(item, index) {
      if (this.cancelDisabled) {
        return
      }
      const self = this
      let msg = (item.subscheme ? item.subscheme.name : item.scheme.name) + '\n' + item.patient.name + '/' + (item.patient.mobile ? item.patient.mobile : '')
      Dialog.alert({
        title: '是否撤销以下病例',
        message: msg,
        theme: 'round-button',
        confirmButtonColor: '#4B99FF',
        closeOnClickOverlay: true
      }).then(() => {
        this.cancelDisabled = true
        cancelRecord({
          record_id: item.id,
          doctor_id: this.userId
        })
          .then(res => {
            this.cancelDisabled = false
            this.$router.replace({
              path: '/case',
              query: {
                record_id: item.id,
                scheme_id: item.scheme_id,
                subscheme_id: item.subscheme_id,
                phase: item.phase,
                patient_id: item.phase > 1 ? item.patient_id : '',
                patient_type: this.currentPatientType
              }
            })
          })
          .catch(err => {
            this.cancelDisabled = false
            Toast(err.response.data.message)
          })
      })
    },
    back() {
      this.$router.replace({
        path: '/sub_project'
      })
      // if(this.short_name == 'bxzleq'){//靶向治疗二期需求跳转
      //     this.$router.replace({
      //         path: '/me'
      //     })
      // }else{
      //     this.$router.replace({
      //         path: '/sub_project'
      //     })
      // }
    },
    checkOpe(item, type) {
      let id = item.subscheme_id ? item.subscheme_id : item.scheme_id
      // 检查操作是否禁用，id: 子方案id或方案id, type: 操作类型，返回true为可用
      return this.operate_limit.hasOwnProperty(id) && this.operate_limit[id].indexOf(type) !== -1 ? false : true
    },
    checkNew() {
      if (!this.operate_limit) {
        return true
      } else {
        let arr = this.operate_limit[this.sub_id]
        return arr && arr.indexOf(1) !== -1 ? false : true
      }
    },
    // 初始化函数 里面请求了病例
    async init() {
      // console.log(this.userId,'userId');
      // console.log(this.doctor_id,'doctor_id');
      this.doctorExamineInfo = await projectDoctor()
      // console.log(this.doctorExamineInfo)

      if (this.short_name == 'linai') {
        let { items } = await getSubschemeId({ params: { name: '新征程' } })
        this.subscheme_id = items[0].id
        console.log('获取新征程项目的子方案id:')
        await recordSort({
          params: {
            doctor_id: this.userId,
            subproject_id: this.sub_id,
            expand: 'doctor,patient,scheme,subscheme,nextRecord,canNextRecord,canCancel,subproject, editNextTip,canFollow,existFollow',
            subscheme_id: this.subscheme_id
          }
        }).then(res => {
          let { items } = res
          // console.log('鳞癌项目获取新病例:', res)
          this.caseList = items
        })
      } else if (this.activeName == '随访信息') {
        // console.log("meijinlai");
        let { items } = await followInfo()
        this.caseList = items
      } else {
        let { items } = await record()
        // console.log(items, 'items')
        this.caseList = items
        this.useRecordSort(this.up_or_down) //默认按时间降序排序
      }
      // console.log(this.caseList,'this.caseList');

      this.linkScheme = await getLinkScheme({
        params: {
          project_doctor_id: this.doctor_id
        }
      })
      if (this.linkScheme.can_bankcard) {
        // console.log('已有的银行卡号：', this.linkScheme.number)
        this.bankForm.bancard_num = this.linkScheme.number
        this.showBank = true
      }
      const data = await addBankList({ project_doctor_id: this.doctor_id })
      this.bankList = data.options
      // console.log('是否要显示创建病例信息按钮', this.linkScheme.is_close_record)
      //  1表示关闭；0表示未关闭 (关闭则不显示按钮)
      if (this.linkScheme.is_close_record == 1) {
        this.ifShowCreateBtn = false
      } else {
        this.ifShowCreateBtn = true
      }
      // if (this.short_name == 'llsh' || this.short_name == 'llsheq') {
      //   this.ifShowCreateBtn = false
      // }
    },
    statusImg(item) {
      if (item.audit_status == '待上传' || item.status == '待上传') {
        return '/static/uploadAfter.svg'
      } else if (item.audit_status == '审核驳回') {
        return '/static/failAfter.svg'
      } else if (item.audit_status == '等待审核') {
        return '/static/loadingAfter.svg'
      } else {
        if (item.pay_status == '等待领取') {
          //文字更改需求1452
          return '/static/paying.svg'
        } else if (item.pay_status == '发放成功') {
          return '/static/paid.svg'
        } else if (item.pay_status == '发放失败') {
          return '/static/pay_failed.svg'
        } else {
          return '/static/successAfter.svg'
        }
        // if (item.canNextRecord == 1) {
        //     return '/static/successAfter.svg'
        // } else {
        //     return '/static/successAfter.svg'
        // }
      }
    },
    getcolor(item) {
      if (item.audit_status == '待上传' || item.status == '待上传') {
        return '#1CB49D'
      } else if (item.audit_status == '审核驳回') {
        return '#FF6864'
      } else if (item.audit_status == '等待审核') {
        return '#4B99FF'
      } else {
        if (item.pay_status == '等待领取') {
          //修改文字需求1452
          return '#4B99FF'
        } else if (item.pay_status == '发放成功') {
          return '#06BC5C'
        } else if (item.pay_status == '发放失败') {
          return '#FF6864'
        } else {
          return '#06BC5C'
        }
        // if (item.canNextRecord == 1) {
        //     // return '#636363'
        //     return '#06BC5C'
        // } else {
        //     return '#06BC5C'
        // }
      }
    },
    // 病例为空时 创建病例
    async next() {
      // if (!this.doctorInfo || this.doctorInfo.is_mobile_verify != 1) {
      //   this.verifyDoctorId = this.userId;
      //   this.verifyBankcard = this.doctorInfo?this.doctorInfo.bankcard:'';
      // //   this.showVerify = true;
      //   return false;
      // }

      if (this.doctorExamineInfo.resign_status == 1 || this.doctorExamineInfo.resign_status == 3) {
        Dialog.alert({
          message: this.short_name == 'bxzleq' ? '请点击确认签署补充服务协议' : '需退出到选择病例中心前重新签署服务协议',
          confirmButtonColor: '#4B99FF'
        }).then(() => {
          if (this.short_name == 'bxzleq') {
            this.back()
          }
        })
        return
      }
      if (this.doctorExamineInfo.resign_status == 4) {
        Dialog.alert({
          message: '请耐心等待审核',
          confirmButtonColor: '#4B99FF'
        }).then(() => {})
        return
      }

      if (this.short_name == 'sshy' && this.doctorExamineInfo.is_create_record != 1) {
        Dialog.alert({
          message: `您好，您在本项目上传的病例数已到上限，详情请联系项目办。${this.project_email ? '<br>项目官员邮箱：' + this.project_email : ''}`,
          confirmButtonColor: '#4B99FF'
        }).then(() => {})
        return
      }

      this.isDisabled = true
      // 注：靶向治疗二期不跳转方案选择列表 (该功能已取消)
      if (!this.linkScheme.scheme_id && !this.linkScheme.subscheme_id) {
        if (this.short_name == 'fcah') {
          this.$router.push({
            name: 'preFilling'
            // path: '/list'
          })
        } else {
          this.$router.replace({
            path: '/list'
          })
          Toast.clear()
        }
      } else {
        // if(this.short_name == 'bxzleq'){   // 注：靶向治疗二期不跳转方案选择列表 默认选择首个方案
        //     console.log("this.linkScheme",this.linkScheme)
        //     let res_data = await getScheme()
        //     let scheme_list = res_data[0].subscheme
        //     console.log("scheme_list",scheme_list)
        //     this.linkScheme.scheme_id = scheme_list[0].scheme_id
        //     this.linkScheme.subscheme_id = scheme_list[0].id
        // }
        let reqData = {
          project_doctor_id: this.doctor_id
        }
        if (this.linkScheme.subscheme_id) {
          reqData.subscheme_id = this.linkScheme.subscheme_id
        } else if (this.linkScheme.scheme_id) {
          reqData.scheme_id = this.linkScheme.scheme_id
        }
        await recordCanSubmit(reqData)
          .then(res => {
            if (res.can) {
              this.$router.replace({
                name: 'case',
                query: {
                  scheme_id: this.linkScheme.scheme_id,
                  subscheme_id: this.linkScheme.subscheme_id ? this.linkScheme.subscheme_id : '',
                  enable: 1
                }
              })
              Toast.clear()
            } else {
              Toast.clear()
              Toast('项目上传病例数已达上限。')
            }
            this.isDisabled = false
          })
          .catch(err => {
            this.disabled = false
            Toast.clear()
            Toast(err.response.data.message)
            this.isDisabled = false
          })
      }
    },
    findIndex(arr, val) {
      for (let i in arr) {
        let ele = arr[i]
        if (ele.name == ele.val) {
          // console.log('findIndex', i)
          this.$forceUpdate
          return i
        }
      }
    },
    getOption(list, val) {
      let arr = list.filter(item => {
        if (item.value) {
          return item.value.indexOf(val) != -1
        } else if (item.name) {
          return item.name.indexOf(val) != -1
        }
      })
      // console.log('getOption', arr)
      return val ? arr : []
    },
    confirmBank(val) {
      if (val) {
        this.bankForm.bank_name = val.value
        this.showBankList = false
      } else {
        Toast('请先输入关键字查找开户行')
      }
    },
    bankSubmit() {
      if (!this.bankForm.bancard_num || !this.bankForm.bank_name || !this.bankForm.bank_branch) {
        Toast('请完善资料')
        return false
      } else if (!bankCardLength(this.bankForm.bancard_num)) {
        Toast('请输入正确的银行卡号')
        return false
      } else if (this.bankForm.bank_branch && this.short_name === 'sshy') {
        if (this.bankForm.bank_branch) {
          const tempStr = this.bankForm.bank_branch
          if (!tempStr.includes('市')) {
            Toast('支行信息填写有误')
            return false
          }
        }
      }
      this.bankForm.project_doctor_id = this.doctor_id
      this.bankDisabled = true
      addBankcard(this.bankForm)
        .then(res => {
          this.bankDisabled = false
          this.showBank = false
        })
        .catch(err => {
          this.bankDisabled = false
          Toast(err.response.data.message)
        })
    },
    // 查看病例
    viewCase(item) {
      // console.log(item, '随访查看')
      // 若查看的是附件信息
      let query
      if (item.count) {
        query = {
          record_id: item.record_id,
          patient_name: item.patient ? item.patient.name : '',
          patient_idsn: item.patient ? item.patient.idsn : '',
          is_followup: this.is_followup,
          template_id: item.template_id,
          follow_id: item.id
        }
      } else {
        console.log('jingzheerma')
        query = {
          record_id: item.id,
          scheme_id: item.scheme_id,
          subscheme_id: item.subscheme_id,
          phase: item.phase,
          patient_id: item.phase > 1 ? item.patient_id : '',
          remark: item.audit_status == '审核驳回' ? item.audit_remark : '',
          patient_type: this.currentPatientType
        }
      }

      this.$router.replace({
        path: '/caseview',
        query
      })
    },
    // 点击病例打开选择框
    async openSelect(item, index) {
      // console.log(item, 'item')
      // 这里写选项
      //查询医生状态
      if (this.doctorExamineInfo.resign_status == 1 || this.doctorExamineInfo.resign_status == 3) {
        Dialog.alert({
          message: this.short_name == 'bxzleq' ? '请点击确认签署补充服务协议' : '需退出到选择病例中心前重新签署服务协议',
          confirmButtonColor: '#4B99FF'
        }).then(() => {
          if (this.short_name == 'bxzleq') {
            this.back()
          }
        })
        return
      }
      if (this.doctorExamineInfo.resign_status == 4) {
        Dialog.alert({
          message: '请耐心等待审核',
          confirmButtonColor: '#4B99FF'
        }).then(() => {})
        return
      }
      item.patient && (this.currentPatientType = item.patient.type)
      // console.log('this.currentPatientType', this.currentPatientType)
      this.GET_IF_FROZEN() //查询若医生冻结则显示弹窗
      await this.GET_IF_CLOSED()
        .then(() => {
          if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
        })
        .catch(err => {
          Toast(err.response.data.message)
        })
      if (this.ifClosed) return
      let arr = []
      let opeView = [{ name: '查看' }]
      let opeEdit = [{ name: '编辑' }]
      let opeDel = [{ name: '删除' }]
      let opeRefresh = [{ name: '刷新' }]
      let opeRedo = [{ name: '撤销审核' }]
      let opeNext = [{ name: '上传下一阶段病例' }]
      let opeNewfollow = [{ name: '创建随访信息' }]
      // 看状态
      if (item.audit_status == '待上传' || item.audit_status == '审核驳回') {
        // console.log('item:', item)
        // console.log(this.ifShowCreateBtn)
        this.actions = this.checkOpe(item, item.audit_status == '待上传' ? 3 : 7) ? opeView : []
        if (this.ifShowCreateBtn == false) {
          //若不显示创建病例按钮则不显示编辑按钮
          // if (this.short_name == 'llsh' || this.short_name == 'llsheq') {
          //   console.log('item:', item)
          //   if (item.is_special == 1 || item.phase > 2) {
          //     //例例生辉特殊病例正常显示
          //     if (item.phase === 1 && this.short_name == 'llsheq' && ['待上传', '审核驳回'].includes(item.audit_status)) {
          //       arr = [{ name: '编辑' }]
          //     } else {
          //       arr = [{ name: '编辑' }, { name: '删除' }]
          //     }
          //   } else {
          //     arr = []
          //   }
          // } else {
          arr = this.checkOpe(item, item.audit_status == '待上传' ? 4 : 8) ? opeDel : []
          // }
        } else {
          if (item.subscheme && item.subscheme.name == '精锐计划') {
            arr = [{ name: '删除' }]
          } else {
            arr = [...(this.checkOpe(item, item.audit_status == '待上传' ? 2 : 6) ? opeEdit : []), ...(this.checkOpe(item, item.audit_status == '待上传' ? 4 : 8) ? opeDel : [])]
          }
        }
        if (item.nextRecord) {
          arr = arr.filter(item => item.name !== '删除')
        }
      } else if (item.audit_status == '等待审核') {
        this.actions = this.checkOpe(item, 5) ? opeView : []
        arr = opeRefresh
        if (item.canCancel == '1') {
          arr = [...opeRedo, ...arr]
        }
      } else if (item.audit_status == '审核通过') {
        this.actions = this.checkOpe(item, 9) ? opeView : []
        if (item.canNextRecord == 1) {
          if (this.ifShowCreateBtn == false) {
            //若不显示创建病例按钮的时候 不显示上传下一阶段病例
            // if (this.short_name == 'llsh') {
            //   if (item.is_special == 1 || item.phase != 1) {
            //     //例例生辉特殊病例正常显示 || 例例生辉拦截第一阶段生成第二阶段
            //     arr = [{ name: '上传下一阶段病例' }]
            //   }
            // }
            // if (this.short_name == 'llsheq') {
            //   arr = [{ name: '上传下一阶段病例' }]
            // }
          } else {
            arr = this.checkOpe(item, 10) ? opeNext : []
          }
        }
        // console.log(item);
        // console.log(item.canFollow,'tem.canFollow');
        if (item.canFollow == 1) {
          arr = [...arr, ...opeNewfollow]
        }
      } else if (this.activeName == '随访信息') {
        if (item.status == '待上传') {
          this.actions = opeView
          arr = [...opeEdit, ...opeDel]
        } else if (item.status == '已提交') {
          this.actions = opeView
        }
      }
      this.actions = [...this.actions, ...arr]
      if (this.actions.length > 0) {
        this.currentIndex = index
        this.currentItem = item
        this.showAction = true
      }
    },
    // 选项点击
    onSelect(item) {
      if (item.name == '查看') {
        this.viewCase(this.currentItem)
      } else if (item.name == '删除') {
        this.delRecord(this.currentItem, this.currentIndex)
      } else if (item.name == '撤销审核') {
        this.cancelRecord(this.currentItem, this.currentIndex)
      } else if (item.name == '创建随访信息') {
        this.followProgress(this.currentItem, this.currentIndex)
      } else {
        this.progress(this.currentItem, this.currentIndex)
      }
    },

    followProgress(item, index) {
      // console.log(item,"followProgress");
      this.$router.replace({
        path: '/case',
        query: {
          template_id: item.subproject.template_id,
          is_followup: item.subproject.is_followup,
          record_id: item.id,
          patient_name: item.patient?.name,
          patient_idsn: item.patient?.idsn
        }
      })
    },

    // 切换tab
    async changeTab(name, title) {
      this.activeName = name
      this.init()
    }
  }
}
</script>

<style lang="scss">
.progress {
  // height: 100%;
  // max-height: calc( 100vh );
  width: 100%;
  // padding-top: 10px;
  box-sizing: border-box;
  // padding: 10px 11px 10px 11px;
  padding-bottom: constant(safe-area-inset-bottom) + 70;
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: 170px;
  .dialog_layer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .van-picker__confirm {
    color: #4b99ff;
  }
  .van-picker__cancel,
  .van-picker__confirm {
    word-break: keep-all;
  }
  .bank_search {
    width: 100%;
    padding: 10px 0;
  }

  .sort_title {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 14px;
    color: #292929;
    font-size: 15px;
    font-family: PingFangSC-Medium;
    .default_text {
      color: #4b99ff;
    }
  }
  .sort_type_text {
    width: 100%;
    font-size: 15px;
    text-align: left;
    text-indent: 16px;
    font-family: PingFangSC-Regular;
  }
  .on_type {
    color: #4b99ff;
  }

  .search_box {
    padding: 0 0 0 16px;
    margin-top: 9px;
    background: #ffffff;
    padding-bottom: 9px;
    // margin-bottom: 15px;
    .icon_box {
      display: flex;
      .sort_icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .van-tabs__wrap {
    margin: 0;
    .van-tabs__nav--line {
      .van-tabs__line {
        background-color: #4b99ff !important;
      }
      .van-tab--active {
        border: none;
        background: transparent !important;
        // border-bottom: 3px solid #4B99FF !important;
        font-family: PingFangSC-Medium;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .van-tabs__nav--card {
      border: none;
      .van-tabs__line {
        background-color: #4b99ff !important;
      }
      .van-tab--active {
        border: none;
        background: transparent !important;
        // border-bottom: 3px solid #4B99FF !important;
        font-family: PingFangSC-Medium;
        font-weight: bold;
        font-size: 14px;
      }

      .van-tab {
        font-size: 14px;
        border: none;
        color: #565656;
      }
    }
  }

  .top_box {
    background: #ffffff;
  }
  .title_box {
    display: flex;
    align-items: center;
    // background: lemonchiffon;
    padding: 7px 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 2px;
    background: #ffffff;
    .title {
      font-size: 13px;
      color: #4b99ff;
      background-color: rgba(75, 153, 255, 0.16);
      padding: 3px;
      border-radius: 3px;
      margin-right: 5px;
    }
    .proj_name {
      font-size: 15px;
      color: #292929;
    }
  }

  .statusList {
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .van-tab__text--ellipsis {
    font-size: 16px;
  }
  // .tabs_box{
  //     // background: lemonchiffon;
  // }
  .title_size {
    font-size: 16px;
  }
  .tabs {
    // padding-top: 15px;
    box-sizing: border-box;
    margin-top: 15px;
  }
  .empty_box {
    padding-top: 35%;
    .img_box {
      .tip_text {
        font-size: 14px;
        color: #c2c2c4;
      }
    }
    .btn_box {
      margin-top: 22px;
      .create_case_btn {
        background: #4b99ff;
        padding: 11px 36px;
        border: 1px solid #4b99ff;
      }
    }
  }
  .messageList {
    height: 100%;
    padding: 0 12px;
    .wrap {
      flex-wrap: wrap;
    }
    .messageOne {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 8px 0px rgba(22, 23, 24, 0.14);
      border-radius: 11px;
      display: flex;
      padding: 14px 17px 17px;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: 12px;
      position: relative;

      .content_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .content {
        width: 100%;
        // flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: left;
        box-sizing: border-box;
        // width: 266px;
        // width: 0%;
        .bottom {
          display: flex;
          justify-content: space-between;
          // background: palegoldenrod;
          align-items: center;
        }

        .head {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          // margin-bottom: 8px;
          margin-bottom: 5px;
          .case_item_box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-items: center;
            .case_name {
              font-size: 13px;
              color: #ffffff;
              background: #32c8c6;
              padding: 2px 10px;
              border-radius: 10px;
              margin-right: 5px;
              // white-space: nowrap;
            }
            span {
              font-size: 12px;
              // font-weight: bold;
              // color: #a5a5a5;
              color: #292929;
              font-family: PingFangSC-Regular;
              // word-break: break-all;
              white-space: nowrap;
            }
          }
          img {
            width: 13px;
            height: 17px;
            margin-right: 7px;
          }
        }

        .time,
        .status {
          font-size: 12px;
          // color: #565656;
          color: #292929;
          text-align: center;
          margin-top: 5px;
          font-family: PingFangSC-Regular;
        }
        .time {
          text-indent: 5px;
        }
        .patient {
          font-size: 15px;
          font-weight: bold;
          color: #656262;
          font-family: PingFangSC-Regular;
          text-align: left;
          text-indent: 5px;
        }
      }

      .shape_bg {
        height: 23px;
        background-color: rgba(10, 194, 99, 0.08);
        border-radius: 8px;
        flex: 1;
        font-size: 12px;
        color: #06bc5c;
        font-family: PingFangSC-Medium;
        line-height: 23px;
        margin-top: 8px;
      }
      .taller-shape-bg {
        height: 46px;
      }
      .icon {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        position: relative;

        img {
          // width: 18px;
          width: 22px;
          margin: 0 13px;
        }

        .status {
          font-size: 12px;
          font-family: PingFangSC-Medium;
          text-align: center;
          min-width: 48px;
          .status-info {
            color: #999;
          }
        }
      }
      .delete {
        position: absolute;
        top: -4px;
        right: 0;
      }
    }
  }
}
.dialog {
  .van-dialog__header--isolated {
    padding: 24px 10px !important;
  }
}
</style>
